import { useState, Fragment } from "react";
import { useRouter } from 'next/router'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import dynamic from "next/dynamic";
import { addDataLayerEvent } from "../../utils/data.layer.utils/data.layer.util";

const Input = dynamic(() => import("../Shared/FormFields/Input"));

const Loading = props => {
	const { searchOpen, close } = props;
	const router = useRouter()

	const [searchTerm, setSearchTerm] = useState("");

	if (typeof document !== "undefined") {
		if (searchOpen) {
			document.getElementsByTagName("body")[0].classList.add("modalOpen");
		} else {
			document
				.getElementsByTagName("body")[0]
				.classList.remove("modalOpen");
		}
	}

	const modal = {
		enter: {
			y: "-50%",
			x: "-50%",
			opacity: 1,
			zIndex: 99999999999,
		},
		exit: {
			y: "0",
			x: "-50%",
			opacity: 0,
			zIndex: -4,
		}
	};

	const shade = {
		enter: { opacity: 1, zIndex: 99999999999 },
		exit: { opacity: 0, zIndex: -4, }
	};

	return (
		<Fragment>
			<motion.div
				initial="exit"
				animate={searchOpen ? "enter" : "exit"}
				variants={shade}
				className={`searchShade ${searchOpen ? "enter" : "exit"}`}
				onClick={close}
			/>
			<motion.div
				initial="exit"
				animate={searchOpen ? "enter" : "exit"}
				variants={modal}
				className={`searchModel ${searchOpen ? "enter" : "exit"}`}
				transition={{
					type: "spring",
					stiffness: 1000,
					damping: 15,
					duration: 0.3
				}}
			>
				<div className="close right" onClick={close}>
					<FontAwesomeIcon icon={["far", "times"]} size={"2x"} />
				</div>
				<div className="searchModelHolder">
					<div className="customContainer">
						<div className="row">
							<div className="col 12">
								<h4>What are you looking for?</h4>
							</div>
							<div className="col s12 searchInput">
								<Input
									value={searchTerm}
									onChange={setSearchTerm}
									placeholder="Search"
									title=""
									showError={""}
								/>
							</div>
							<div className="col s12 right-align">
								<a
									className="waves-effect waves-light hButton buttonRed requestAPart"
									title="Search"
									onClick={(e) => {
										e.preventDefault();
										addDataLayerEvent("search", {"searchTerm": searchTerm});
										close();
										router.push(`/search?q=${searchTerm}`);
									}}
								>
									Search
								</a>
							</div>
						</div>
					</div>
				</div>
			</motion.div>
		</Fragment>
	);
};

export default Loading;
